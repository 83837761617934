import { render, staticRenderFns } from "./Saved.vue?vue&type=template&id=51f36ea1&scoped=true"
import script from "./Saved.vue?vue&type=script&lang=js"
export * from "./Saved.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51f36ea1",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CIcon: require('@chakra-ui/vue').CIcon, CButton: require('@chakra-ui/vue').CButton, CPopoverTrigger: require('@chakra-ui/vue').CPopoverTrigger, CFlex: require('@chakra-ui/vue').CFlex, CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CPopoverBody: require('@chakra-ui/vue').CPopoverBody, CPopoverContent: require('@chakra-ui/vue').CPopoverContent, CPopover: require('@chakra-ui/vue').CPopover, CBox: require('@chakra-ui/vue').CBox})
