<template>
  <c-box borderRadius="8px" bg="#ffffff77" w="100%" p="10px" my="10px">
    <c-flex>
      <c-image
          rounded="lg"
          w="280px"
          h="130px"
          :src="event.image_url"
          alt="user-avatar"
      />
      <c-box ml="4" w="65%" color="gray.500">
        <c-text flex="column" fontSize="xs" fontWeight="400">{{ event.date }}</c-text>
        <c-text style="overflow-y: hidden; text-overflow: ellipsis; height: 50px" flex="column" fontSize="md" fontWeight="400">{{ event.description }}</c-text>
        <c-text fontSize="lg" fontWeight="300">{{ event.name }}</c-text>
        <c-flex mr="3" justify="start">
          <c-button variant-color="vc-orange" variant="link">
            Attend
          </c-button>
        </c-flex>
      </c-box>
    </c-flex>
  </c-box>
</template>

<script>
export default {
  name: 'SavedEventCard',
  props: ['event'],
}
</script>

<style scoped>

</style>